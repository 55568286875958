// extracted by mini-css-extract-plugin
export var active = "Navigation-module--active--4fbbc";
export var auth = "Navigation-module--auth--11f00";
export var badge = "Navigation-module--badge--f2dff";
export var balance = "Navigation-module--balance--b9029";
export var blue = "#0eb3f2";
export var button = "Navigation-module--button--4b566";
export var container = "Navigation-module--container--cbd08";
export var count = "Navigation-module--count--c7562";
export var emphasis = "Navigation-module--emphasis--68b05";
export var green = "#57d33b";
export var greenLink = "Navigation-module--green-link--7d1c2";
export var grey = "var(--tone-mid)";
export var hideOnMobile = "Navigation-module--hideOnMobile--d5872";
export var icon = "Navigation-module--icon--a8ff0";
export var left = "Navigation-module--left--5c3d0";
export var name = "Navigation-module--name--946dd";
export var notchGap = "32px";
export var notifications = "Navigation-module--notifications--3ac0b";
export var notificationsHeader = "Navigation-module--notificationsHeader--54620";
export var orange = "#f8662b";
export var panel = "Navigation-module--panel--044b1";
export var panelButton = "Navigation-module--panelButton--a53bf";
export var panelPopover = "Navigation-module--panelPopover--3cfab";
export var panels = "Navigation-module--panels--394c7";
export var perk = "Navigation-module--perk--5f765";
export var perks = "Navigation-module--perks--c4875";
export var perksContainer = "Navigation-module--perksContainer--f2850";
export var perksPopover = "Navigation-module--perksPopover--f1872";
export var readMoreContainer = "Navigation-module--readMoreContainer--8e698";
export var red = "#ff2741";
export var right = "Navigation-module--right--268c3";
export var walletSpacing = "Navigation-module--walletSpacing--89151";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";